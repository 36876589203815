
<template>
<form autocomplete="off" class="">
    <is-loading v-if="isLoading" :box="true" />
    <div class="row gx-lg-4 g-3"> 
        <div class="col-md-6">
            <label for="category_id" class="form-label">Category</label>
            <select @change="categoryChange()" v-model="data.category_id" 
                name="category_id" id="category_id" class="form-select">
            <option disabled :value="null" >--choose category--</option>
            <option v-for="(category, coIndex) in categories" :key="coIndex" 
            :value="category.id">{{category.title}}</option>
            </select>
        </div>
        <div class="col-md-6">
            <label for="sub_category_id" class="form-label">Sub-category</label>
            <select @change="subCategoryChange()" v-model="data.sub_category_id" 
                name="sub_category_id" id="sub_category_id" class="form-select">
            <option disabled :value="null" >--choose sub-category--</option>
            <option v-for="(subCategory, coIndex) in subCategoryOptions" :key="coIndex" 
            :value="subCategory.id">{{subCategory.title}}</option>
            </select>
        </div>
        <div class="col-md-6">
            <label> Medicine </label>
            <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="name" label="name"
            :options="medicineOptions" 
            v-model="data.medicine_id" placeholder="--select medicine --"></multiselect>
        </div> 
        <div class="col-lg-6">
            <label class="form-label">Allocated between</label>
            <flat-pickr
                v-model="data.created_between"
                :config="rangeConfig"
                placeholder="Select date"
                class="form-control flatpickr-input"
            ></flat-pickr>
        </div>
        <div class="col-lg-12">
            <label class="form-label">Quantity range</label>
            <div class="pt-4">
                <Slider v-model="data.quantity_between" :merge="10" :max="1000" />
            </div>
        </div>
    </div>
    <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>

            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
    </div>
</form>
</template>
    
<script>

import Multiselect from '@vueform/multiselect'
import flatPickr from "vue-flatpickr-component";
import IsLoading from '@/components/IsLoading.vue'
import Slider from "@vueform/slider";

export default {
    components: {
        IsLoading,
        Multiselect,
        Slider,
        flatPickr
    },
    data() {
        return {
            isLoading: false,
            data: {
                created_between: "",
                category_id: null,
                sub_category_id: null,
                medicine_id: null,
                quantity_between: [0, 900]
            },
            dateConfig:{
                wrap: true, 
                altFormat: 'M j, Y',
                altInput: true,
                dateFormat: 'Y-m-d',
                // enableTime: true,
            },
            rangeConfig: {
                mode: "range",
                dateFormat: "Y-m-d",
                altInput: true,
                showMonths: 2,
                altFormat: "F j, Y",
                // minDate: "today",
                maxDate: new Date().fp_incr(1)
            },
            mirrorData: null,
            subCategoryOptions:[],
            medicineOptions:[]
        }
    },
    computed:{
        categories(){
            return this.$store.state.category.list
        },
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        getOptionData(){
            this.$store.dispatch('category/fetchAllCategories')
            .then(response => this.isLoading = false )
        },
        categoryChange(initialize = true){
            if(initialize){
                this.data.sub_category_id = null;
                this.data.medicine_id = null;
            }
            this.subCategoryOptions = [];
            this.medicineOptions = [];
            if(!this.data.category_id) { return; }
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/sub-categories?category_id=${this.data.category_id}`)
                .then((response) => {
                this.isLoading = false;
                if(response.data.success){
                    this.subCategoryOptions = response.data.data
                }
            })
        },
        subCategoryChange(initialize = true){
            if(initialize){
                this.data.medicine_id = null;
            }
            this.medicineOptions = [];
            if(!this.data.sub_category_id) { return; }
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/medicines?sub_category_id=${this.data.sub_category_id}`)
                .then((response) => {
                this.isLoading = false;
                if(response.data.success){
                    this.medicineOptions = response.data.data
                }
            })
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
    },
    created(){
        this.getOptionData()
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
